import React, { useState } from "react";
import { Participant } from "../types/participant";
import { addParticipant, findMaxClientId, generateUserId, removeParticipantbyUserID } from "../hook/useParticipants";
import { LocalStorage } from "../store/LocalStorage";
import { ReactComponent as Person } from '../assets/images/person.svg'
import { ReactComponent as PlusCircleFill } from '../assets/images/plus-circle-fill.svg'
import { ReactComponent as Trash3Fill } from '../assets/images/trash3-fill.svg'
import {useTranslation} from "react-i18next";

const ParticipantListItem = ({ participant, roomOwnerId, meetingId }: { participant: Participant, roomOwnerId: string, meetingId: string }) => {
	return (
		<li className="list-group-item">
			<Person className="person"/>
			<span className="participant-list-text">{participant.name}</span>
			{participant.isAddedByUser && participant.clientId === LocalStorage.uid ?
			<button
				className='btn btn-danger participant-list-btn'
			onClick={() => {removeParticipantbyUserID(roomOwnerId, meetingId, participant.id)}}><Trash3Fill className="trash3-fill"/></button>
			: <></>
			}
		</li>
	)
}

const ParticipantsWidget = ({ participants, roomOwnerId, meetingId }: { participants: Participant[], roomOwnerId: string, meetingId: string }) => {
	const {t} = useTranslation()
	const [newUserName, setNewUserName] = useState<string>('')
	const handleOnClick = () => {
		if (newUserName !== "") {
			const clientId = LocalStorage.uid as string
			const maxId = findMaxClientId(participants, clientId)
			const userId = generateUserId(clientId, maxId + 1)
			addParticipant(roomOwnerId, meetingId, clientId, userId, newUserName, true)
			setNewUserName("")
		}
	}
	return (
	<ul className="list-group participant-list">
		{participants.map((participant, index) => (
		participant.state === "ONLINE"? <ParticipantListItem key={index} participant={participant} roomOwnerId={roomOwnerId} meetingId={meetingId} /> : null
		))}
		<li className="list-group-item">
			<Person className="person"/>
			<input
				placeholder={t('参加者名を入力...')}
				className="participant-list-input"
				value={newUserName}
				onChange={(event) => setNewUserName(event.target.value)}
			/>
			<button className='btn btn-success participant-list-btn stick' onClick={handleOnClick}><PlusCircleFill className="plus-circle-fill"/></button>
		</li>
	</ul>
	)
}

export default ParticipantsWidget
