import 'react-tooltip/dist/react-tooltip.css'
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import { Option, TypeaheadPropsAndState } from "react-bootstrap-typeahead/types/types";
import { Tooltip } from 'react-tooltip'
import { ReactComponent as CaretUpFill } from '../assets/images/caret-up-fill.svg'
import { ReactComponent as CaretDownFill } from '../assets/images/caret-down-fill.svg'
import { ReactComponent as PlusCircleFill } from '../assets/images/plus-circle-fill.svg'
import { ReactComponent as PencilFill } from '../assets/images/pencil-fill.svg'
import { ReactComponent as Trash3Fill } from '../assets/images/trash3-fill.svg'
import { isDefaultMinutes, changeLanguageAgenda, changeLanguageAgendaList } from "../util/util";
import { LanguageList } from "../util/language";
import { LocalStorage } from "../store/LocalStorage";
import useQuery from "../hook/useQuery";

interface ModalProps {
    open?: boolean;
    onClose?: () => void;
}

interface AgendaModalProps extends ModalProps {
    defaultValue: string;
}

const useModal = (modalId: string, open?: boolean, onClose?: () => void) => {
    const modalRef = useRef<any>();

    useEffect(() => {
        if (!modalRef.current) {
            modalRef.current = new window.bootstrap.Modal(document.getElementById(modalId), {
                keyboard: false
            });
        }

        if (open) {
            modalRef.current.show();
        } else {
            modalRef.current.hide();
        }
    }, [modalId, open]);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    return { modalRef, handleClose };
};

const useModalValue = (defaultValue: string) => {
    const [value, setValue] = useState(defaultValue);
    const editing = useRef(true);

    const handleValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        editing.current = true;
        setValue(e.target.value);
    }, []);

    return { value, setValue, editing, handleValueChange };
};

export const AgendaAddModal = observer(
  ({
       open,
       onClose,
       onAgendaAdd,
       defaultValue
  }: AgendaModalProps & {
      onAgendaAdd: (val: string) => boolean
  }) => {
    const { t } = useTranslation();
    const { handleClose } = useModal('agendaAdd', open, onClose);
    const { value, handleValueChange } = useModalValue(defaultValue);

    const handleAgendaAdd = useCallback(() => {
        if (onAgendaAdd?.(value)) {
            onClose?.();
        }
    }, [onAgendaAdd, onClose, value]);

    return (
      <div className="modal fade" id="agendaAdd" tabIndex={-1}>
          <div className="modal-dialog modal-lg">
              <div className="modal-content" style={{ borderRadius: '1rem' }}>
                  <div className="modal-header border-bottom-0">
                      <h5 className="modal-title">{t('議題追加')}</h5>
                      <button type="button" className="btn-close" onClick={handleClose} />
                  </div>
                  <div className="modal-body">
                      <div className="container">
                          <div className="row mb-3">
                              <label htmlFor="agendaAddInput" className="col-sm-2 col-form-label">
                                  {t('議題')}
                              </label>
                              <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="agendaAddInput"
                                    value={value}
                                    onChange={handleValueChange}
                                    placeholder={t('議題を入力...')}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={handleClose}>
                          {t('閉じる')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={value === ''}
                        onClick={handleAgendaAdd}
                      >
                          {t('OK')}
                      </button>
                  </div>
              </div>
          </div>
      </div>
    );
});

export const AgendaEditModal = observer((
  {
      open,
      onClose,
      onAgendaEdit,
      defaultValue,
      targetAgenda
  }: AgendaModalProps & {
    onAgendaEdit: (val: string, targetAgenda: string) => boolean;
    targetAgenda: string;
  }) => {
    const { t } = useTranslation();
    const { handleClose } = useModal('agendaEdit', open, onClose);
    const { value, setValue, editing, handleValueChange } = useModalValue(
      defaultValue !== '' ? defaultValue : targetAgenda
    );

    useEffect(() => {
        if (open && !editing.current) {
            setValue(defaultValue !== '' ? defaultValue : targetAgenda);
        }
        if (!open) {
            editing.current = false;
        }
    }, [defaultValue, open, targetAgenda, setValue, editing]);

    const handleAgendaEdit = useCallback(() => {
        if (onAgendaEdit?.(value, targetAgenda)) {
            onClose?.();
        }
    }, [onAgendaEdit, onClose, targetAgenda, value]);

    return (
      <div className="modal fade" id="agendaEdit" tabIndex={-1}>
          <div className="modal-dialog modal-lg">
              <div className="modal-content" style={{ borderRadius: '1rem' }}>
                  <div className="modal-header border-bottom-0">
                      <h5 className="modal-title">{t('議題編集')}</h5>
                      <button type="button" className="btn-close" onClick={handleClose} />
                  </div>
                  <div className="modal-body">
                      <div className="container">
                          <div className="row mb-3">
                              <label htmlFor="agendaEditTargetInput" className="col-sm-2 col-form-label">
                                  {t('変更前の議題')}
                              </label>
                              <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="agendaEditTargetInput"
                                    value={targetAgenda}
                                    readOnly
                                  />
                              </div>
                          </div>
                          <div className="row mb-3">
                              <label htmlFor="agendaEditInput" className="col-sm-2 col-form-label">
                                  {t('変更後の議題')}
                              </label>
                              <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="agendaEditInput"
                                    value={value}
                                    onChange={handleValueChange}
                                    placeholder={t('議題を入力...')}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={handleClose}>
                          {t('閉じる')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={value === ''}
                        onClick={handleAgendaEdit}
                      >
                          {t('OK')}
                      </button>
                  </div>
              </div>
          </div>
      </div>
    );
});

export const AgendaDeleteModal = observer(
  ({
       open,
       onClose,
       onAgendaDelete,
       targetAgenda
  }: ModalProps & {
    onAgendaDelete: (targetAgenda: string) => boolean;
    targetAgenda: string;
  }) => {
    const { t } = useTranslation();
    const { handleClose } = useModal('agendaDelete', open, onClose);

    const handleAgendaDelete = useCallback(() => {
        if (onAgendaDelete?.(targetAgenda)) {
            onClose?.();
        }
    }, [onAgendaDelete, onClose, targetAgenda]);

    return (
      <div className="modal fade" id="agendaDelete" tabIndex={-1}>
          <div className="modal-dialog modal-lg">
              <div className="modal-content" style={{ borderRadius: '1rem' }}>
                  <div className="modal-header border-bottom-0">
                      <h5 className="modal-title">{t('議題削除')}</h5>
                      <button type="button" className="btn-close" onClick={handleClose} />
                  </div>
                  <div className="modal-body">
                      <div className="container">
                          <div className="row mb-3">
                              <label htmlFor="agendaDeleteInput" className="col-sm-2 col-form-label">
                                  {t('削除する議題')}
                              </label>
                              <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="agendaDeleteInput"
                                    value={targetAgenda}
                                    readOnly
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={handleClose}>
                          {t('閉じる')}
                      </button>
                      <button type="button" className="btn btn-primary" onClick={handleAgendaDelete}>
                          {t('OK')}
                      </button>
                  </div>
              </div>
          </div>
      </div>
    );
});

export interface AgendaSelectHandles {
    setAgendaSelected: (newAgendaSelected: string) => void;
    setAgendaEditValue: (newAgendaEditValue: string) => void;
    getAgendaEditValue: () => string;
}

interface AgendaSelectProps {
    typeaheadShow: boolean;
    agenda: string[];
    agendaFilterBy: (option: Option, state: TypeaheadPropsAndState) => boolean;
    agendaValue: string;
    handleAgendaSelect: (selected: Option[]) => void;
    handleAgendaInput: (text: string, event: ChangeEvent<HTMLInputElement>) => void;
    handleAgendaChange: (targetAgenda: string, notifyServer: boolean) => void;
    typeaheadRef: Ref<TypeaheadRef>;
    onAgendaAdd: (val: string) => boolean;
    onAgendaEdit: (val: string, targetAgenda: string) => boolean;
    onAgendaDelete: (targetAgenda: string) => boolean;
}

export const AgendaSelect = forwardRef<AgendaSelectHandles, AgendaSelectProps>(
  ({
       typeaheadRef,
       agendaFilterBy,
       agenda,
       typeaheadShow,
       handleAgendaSelect,
       handleAgendaInput,
       handleAgendaChange,
       agendaValue,
       onAgendaAdd,
       onAgendaEdit,
       onAgendaDelete
   }, ref) => {
    const { t, i18n } = useTranslation();
    const { getSummaryFlag } = useQuery();
    const summaryFlag = getSummaryFlag();

    const [defaultInputValue, setDefaultInputValue] = useState(agendaValue);
    const [agendaList, setAgendaList] = useState(agenda);
    const [typeaheadShowState, setTypeaheadShowState] = useState(typeaheadShow);
    const [showAgendaAddModal, setShowAgendaAddModal] = useState(false);
    const [showAgendaEditModal, setShowAgendaEditModal] = useState(false);
    const [showAgendaDeleteModal, setShowAgendaDeleteModal] = useState(false);
    const [agendaSelected, setAgendaSelected] = useState(LanguageList['ja-JP'].summaryAgenda as string);
    const [agendaEditValue, setAgendaEditValue] = useState('');

    const toggleModal = useCallback((type: 'add' | 'edit' | 'delete', show: boolean) => {
        switch (type) {
            case 'add':
                setShowAgendaAddModal(show);
                break;
            case 'edit':
                setShowAgendaEditModal(show);
                break;
            case 'delete':
                setShowAgendaDeleteModal(show);
                break;
        }
    }, []);

    useImperativeHandle(ref, () => ({
        setAgendaSelected,
        setAgendaEditValue,
        getAgendaEditValue: () => agendaEditValue,
    }));

    const changeLanguageHandler = useCallback((lng: string) => {
        const changeAgenda = changeLanguageAgenda(lng, agendaSelected);
        const changeAgendaList = changeLanguageAgendaList(lng, agendaList);
        setAgendaSelected(changeAgenda);
        setDefaultInputValue(changeAgenda);
        setAgendaList(changeAgendaList);
        setTypeaheadShowState(false);
        queueMicrotask(() => {
            setTypeaheadShowState(true);
        });
    }, [agendaList, agendaSelected]);

    useEffect(() => {
        i18n.on('languageChanged', changeLanguageHandler);
        return () => {
            i18n.off('languageChanged', changeLanguageHandler);
        };
    }, [changeLanguageHandler, i18n]);

    useEffect(() => {
        setDefaultInputValue(agendaValue);
    }, [agendaValue]);

    useEffect(() => {
        setAgendaList(agenda);
    }, [agenda]);

    useEffect(() => {
        setTypeaheadShowState(typeaheadShow);
    }, [typeaheadShow]);

    return (
      <>
          {(agendaList.length !== 0 && typeaheadShowState) && (
            <Typeahead
              ref={typeaheadRef}
              filterBy={agendaFilterBy}
              id="summary-agenda-list"
              className={`${summaryFlag ? ' d-inline-block d-sm-inline-block d-md-none' : ''}`}
              options={agendaList}
              onChange={handleAgendaSelect}
              defaultInputValue={defaultInputValue}
              onInputChange={handleAgendaInput}
              placeholder={t('議題を入力...')}
            >
                {({isMenuShown, toggleMenu}) => (
                  <button
                    id="summary-agenda-toggle-button"
                    className="toggle-button"
                    onClick={toggleMenu}
                    onMouseDown={(e) => {
                        e.preventDefault()
                    }}
                  >
                      {isMenuShown ? <CaretUpFill className="caret-up-fill"/> : <CaretDownFill className="caret-down-fill"/>}
                  </button>
                )}
            </Typeahead>
          )}
          <div className={`list-group${summaryFlag ? ' d-none d-sm-none d-md-block' : ' d-none'}`}>
              {agendaList.map((item) => (
                <button
                  key={item}
                  type="button"
                  onClick={() => handleAgendaChange(item, true)}
                  className={`list-group-item list-group-item-action${item === agendaSelected ? ' active' : ''}`}
                >
                    {item}
                    {!isDefaultMinutes(item) && item === agendaSelected && (
                      <div className="btn-group floating" role="group">
                          <button
                            id="summary-agenda-edit-button-wide"
                            className="btn btn-success control-button edit-button ok"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleModal('edit', true);
                            }}
                            disabled={LocalStorage.roomClosed}
                          >
                              <PencilFill className="pencil-fill"/>
                          </button>
                          <Tooltip
                            anchorSelect="#summary-agenda-edit-button-wide"
                            place="bottom"
                          >
                              {t('議題編集')}
                          </Tooltip>
                          <button
                            id="summary-agenda-delete-button-wide"
                            className="btn btn-danger control-button delete-button ok"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleModal('delete', true);
                            }}
                            disabled={LocalStorage.roomClosed}
                          >
                              <Trash3Fill className="trash3-fill"/>
                          </button>
                          <Tooltip
                            anchorSelect="#summary-agenda-delete-button-wide"
                            place="bottom"
                          >
                              {t('議題削除')}
                          </Tooltip>
                      </div>
                    )}
                </button>
              ))}
              {!LocalStorage.roomClosed && (
                <button
                  id="summary-agenda-add-button-wide"
                  className="list-group-item list-group-item-action list-group-item-success text-center"
                  onClick={() => toggleModal('add', true)}
                >
                    <PlusCircleFill className="plus-circle-fill"/> {t('議題追加')}
                </button>
              )}
          </div>

          <div
            className={`btn-group${summaryFlag ? ' d-inline-block d-sm-inline-block d-md-none btn-group-agenda' : ' btn-group-agenda'}`}
            role="group"
          >
              <button
                id="summary-agenda-add-button"
                className="btn btn-primary control-button add-button ok"
                onClick={() => toggleModal('add', true)}
                disabled={LocalStorage.roomClosed}
              >
                  <PlusCircleFill className="plus-circle-fill"/>
              </button>
              <Tooltip
                anchorSelect="#summary-agenda-add-button"
                place="bottom"
              >
                  {t('議題追加')}
              </Tooltip>

              <button
                id="summary-agenda-edit-button"
                className={`btn btn-success control-button edit-button${agendaSelected !== '' && !isDefaultMinutes(agendaSelected) ? ' ok' : ''}`}
                onClick={() => toggleModal('edit', true)}
                disabled={LocalStorage.roomClosed || !(agendaSelected !== '' && !isDefaultMinutes(agendaSelected))}
              >
                  <PencilFill className="pencil-fill"/>
              </button>
              <Tooltip
                anchorSelect="#summary-agenda-edit-button"
                place="bottom"
              >
                  {t('議題編集')}
              </Tooltip>

              <button
                id="summary-agenda-delete-button"
                className={`btn btn-danger control-button delete-button${agendaSelected !== '' && !isDefaultMinutes(agendaSelected) && agendaEditValue === '' ? ' ok' : ''}`}
                onClick={() => toggleModal('delete', true)}
                disabled={LocalStorage.roomClosed || !(agendaSelected !== '' && !isDefaultMinutes(agendaSelected) && agendaEditValue === '')}
              >
                  <Trash3Fill className="trash3-fill"/>
              </button>
              <Tooltip
                anchorSelect="#summary-agenda-delete-button"
                place="bottom"
              >
                  {t('議題削除')}
              </Tooltip>
          </div>

          <AgendaAddModal
            open={showAgendaAddModal}
            onClose={() => toggleModal('add', false)}
            defaultValue={agendaEditValue}
            onAgendaAdd={onAgendaAdd}
          />
          <AgendaEditModal
            open={showAgendaEditModal}
            onClose={() => toggleModal('edit', false)}
            defaultValue={agendaEditValue}
            targetAgenda={agendaSelected}
            onAgendaEdit={onAgendaEdit}
          />
          <AgendaDeleteModal
            open={showAgendaDeleteModal}
            onClose={() => toggleModal('delete', false)}
            targetAgenda={agendaSelected}
            onAgendaDelete={onAgendaDelete}
          />
      </>
    );
});
