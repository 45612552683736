import { useCallback, useEffect, useRef, useState } from 'react'
import { io, Socket } from 'socket.io-client';
import MediaStreamRecorder from 'msr';
import { useSttDictionaries } from './useSttDictionaries';
import { getLanguageCodes } from '../util/language';
import { HALLUCINATION_WORDS } from '../util/util';
import { UseSpeechArgs, UseSpeechHandles } from '../types/speech';

/**
 * マイクからの音声の録音と解析を行うためのReactフックの表現。
 *
 * @param {Object} UseSpeechArgs - フック設定のためのパラメータ。
 * @param {string} UseSpeechArgs.userId - ユーザーID。
 * @param {string} UseSpeechArgs.roomOwnerId - ルームオーナーのID。
 * @param {boolean} UseSpeechArgs.needInterim - 中間結果が必要かどうか。
 * @param {function} UseSpeechArgs.onMessage - メッセージ処理用の関数。
 * @param {function} UseSpeechArgs.onDataAvailable - 録音データが利用可能になった際の処理を行う関数。
 * @param {function} UseSpeechArgs.onError - エラー発生時の処理を行う関数。
 *
 * @returns {Object} UseSpeechHandles - 録音プロセス用のコールバック関数と状態変数を含むオブジェクト。
 * @returns {boolean} UseSpeechHandles.initialized - 初期化が完了し、接続が確立しているかどうかを示すブール値。
 * @returns {Object} UseSpeechHandles.initializedRef - 接続が確立しているかどうかを参照するrefオブジェクト。
 * @returns {function} UseSpeechHandles.start - 音声文字変換処理を開始するための関数。
 * @returns {function} UseSpeechHandles.stop - 音声文字変換処理を停止するための関数。
 * @returns {function} UseSpeechHandles.setUseStart - このhookを使用するかどうかを設定するための関数。
 */
export const useGoogleSpeech = ({
  userId,
  roomOwnerId,
  needInterim,
  onMessage,
  onDataAvailable,
  onError,
}: UseSpeechArgs): UseSpeechHandles => {
  // hookのイベントハンドラ
  const handlersRef = useRef({
    onMessage,
    onError
  });
  useEffect(() => {
    handlersRef.current = {onMessage, onError};
  }, [onMessage, onError]);

  // 起動するかどうか
  const [useStart, setUseStart] = useState<boolean>(false);

  // ソケット通信
  const [socket, setSocket] = useState<Socket | null>(null);
  const socketRef = useRef(socket);
  useEffect(() => {
    socketRef.current = socket;
  }, [socket]);

  // ソケット通信開通したかどうか
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const isSocketConnectedRef = useRef(isSocketConnected);
  useEffect(() => {
    isSocketConnectedRef.current = isSocketConnected;
  }, [isSocketConnected]);

  // 辞書適用関数
  const {replaceTextByDictionaries} = useSttDictionaries(roomOwnerId);

  // レコーダー
  const mediaRecorderRef = useRef<MediaStreamRecorder>();
  // 音声情報
  const audioInfoRef = useRef<AudioInfo>();

  const recognizing = useCallback( async (text: string) => {
    if(text !== ''){
      // 簡易的なハルシネーション対策
      if (HALLUCINATION_WORDS.some(n => n.test(text))) {
        console.debug('find hallucination word', text);
        return;
      }
      // isFinal が false の場合はドラフト文。 true の場合は最終結果。
      handlersRef.current.onMessage(text, false);
    }
  }, []);

  const recognized = useCallback( async (text: string, language: string) => {
    if(text !== ''){
      // 簡易的なハルシネーション対策
      if (HALLUCINATION_WORDS.some(n => n.test(text))) {
        console.debug('find hallucination word', text);
        return;
      }
      // 辞書登録に従い言葉を置換
      const replaced = await replaceTextByDictionaries(text);
      // isFinal が true の場合は最終結果。
      handlersRef.current.onMessage(replaced, true, language);
    }
  }, [replaceTextByDictionaries]);

  useEffect(() => {
    if(useStart){
      const baseUrl = process.env.REACT_APP_BASE_URL;
      if (!baseUrl) {
        throw new Error("REACT_APP_BASE_URL is not defined");
      }
      const newSocket = io(baseUrl.replace('/api', ''), {
        path: '/speech',
        rejectUnauthorized: false,
        secure: false,
        transports: ['websocket'],
        reconnection: true,
        auth: {
          userId: userId,
        },
      });
      setSocket(newSocket);
      return () => {
        if (newSocket) {
          newSocket.disconnect();
        }
      };
    }
  }, [useStart, userId]);

  const recorderStop = useCallback(() => {
    if(audioInfoRef.current){
      audioInfoRef.current = undefined;
    }
    if(mediaRecorderRef.current){
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.ondataavailable = (blob: Blob) => {};
    }
  }, []);

  const recorderStart = useCallback((stream: MediaStream) => {
    recorderStop();

    mediaRecorderRef.current = new MediaStreamRecorder(stream);
    mediaRecorderRef.current.stream = stream;
    mediaRecorderRef.current.recorderType = MediaStreamRecorder.StereoAudioRecorder;
    mediaRecorderRef.current.mimeType = 'audio/wav';
    mediaRecorderRef.current.audioChannels = 1; // モノラル

    mediaRecorderRef.current.ondataavailable = async (blob: Blob) => {
      if(!socketRef.current){
        return;
      }
      if (!audioInfoRef.current) {
        const data = await blob.arrayBuffer();
        const wavHeader = data.slice(0, 44);
        const dataView = new DataView(wavHeader);
        const channel = dataView.getUint16(22, true);
        const bitDepth = dataView.getUint16(34, true);
        const encoding = `LINEAR${bitDepth}`;
        const sampleRate = dataView.getUint32(24, true);
        audioInfoRef.current = { channel, bitDepth, encoding, sampleRate };
        socketRef.current.emit('startGoogleCloudStream', getLanguageCodes(), audioInfoRef.current.encoding, audioInfoRef.current.sampleRate);
      }

      socketRef.current.emit('binaryData', blob)

      if(process.env.REACT_APP_ENVIRONMENT !== "production"){
        await onDataAvailable(blob);
      }
    }
    mediaRecorderRef.current.start(500);
  }, [onDataAvailable, recorderStop]);

  const start = useCallback( async (stream: MediaStream) => {
      console.log('startGoogleCloudStream ==')
      recorderStart(stream);
    },[recorderStart]);

  const stop = useCallback(() => {
    console.log('stopGoogleCloudStream')
    recorderStop();
    if(socketRef.current){
      socketRef.current.emit('stopGoogleCloudStream')
    }
  }, [recorderStop]);

  useEffect(() => {
    if(socket){
      socket.off();
      socket.on('connect', () => {
        setIsSocketConnected(true);
      })
      socket.on('disconnect', () => {
        setIsSocketConnected(false);
      })
      socket.on('speechData', async (transcriptText: string, interimIndex: number, language: string) => {
        await recognized(transcriptText, language);
      })
      if (needInterim) {
        socket.on('interimData', async (transcriptText: string, interimIndex: number) => {
          await recognizing(transcriptText);
        })
      }
      socket.on('application_error', (msg: string) => {
        onError(msg);
      })
    }
    return () => {
      if(socket){
        socket.emit('stopGoogleCloudStream');
        socket.off();
      }
    }
  }, [needInterim, onError, recognized, recognizing, socket]);

  return {
    initialized: isSocketConnected,
    initializedRef: isSocketConnectedRef,
    start,
    stop,
    setUseStart,
  }
}
