import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'

import { LanguageFlag } from './LanguageFlag'
import { LocalStorage } from '../store/LocalStorage'
import { ILanguages, LanguageList } from '../util/language';
import { useTranslation } from 'react-i18next'

interface LanguageSelectProps {
  onLanguageSelected?: () => void
  title?: boolean
}

export const LanguageSelect = observer(({ onLanguageSelected, title }: LanguageSelectProps) => {
  const { t } = useTranslation()
  const handleLanguageSelect = useCallback((lang: ILanguages | null) => {
    if(lang){
      if(LocalStorage.language.includes(lang)){
        LocalStorage.language = LocalStorage.language.filter(l => l !== lang);
        LocalStorage.setValue('language', [...LocalStorage.language])
      }else if(LocalStorage.language.length < 3){
        LocalStorage.setValue('language', [...LocalStorage.language, lang])
      }
    }
    onLanguageSelected?.()
  }, [onLanguageSelected])

  return (
    <div className='row language-select mx-0'>
      {!!title && (
        <div className='col-12 text-center text-secondary h5'>
          {t('言語選択')}
        </div>
      )}
      {/* ALL をコメントアウト */}
      {/*<div className='col-6 col-sm-4 col-md-3 col-xl-2 mb-2'>
        <LanguageFlag active={null === LocalStorage.language} showName
          language={null} onItemClick={handleLanguageSelect} />
      </div>*/}

      {Object.values(LanguageList).map((language) => (
        <div key={language.id} className='col-6 col-sm-4 col-md-3 col-xl-2 mb-2'>
            <LanguageFlag active={LocalStorage.language.includes(language.id)} showName
            language={language} onItemClick={handleLanguageSelect} />
        </div>
      ))}
    </div>
  )
})
