import './Crystal.css'
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { audioMasterAnalyserBus } from "../util/audioContext"
import AdvisorState from '../store/AdvisorState'

interface CrystalProps {
  onClickCrystal: () => void
  enableAIAdvisor: boolean // AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
  doUserOperation: boolean
}

function onUpdate(element: HTMLVideoElement, audioArray: Uint8Array) {
  audioMasterAnalyserBus.getByteFrequencyData(audioArray)
  const peak = audioArray.reduce((prev, curr) => {
    return Math.max(prev, curr)
  }, 0)
  let peakRange = peak / 255
  peakRange = peakRange * peakRange * peakRange
  const zoomLevel = 100 + Math.max(Math.floor(peakRange * 33), 0)
  element.style.scale = `${zoomLevel}%`
}

export const Crystal = observer(({ onClickCrystal, enableAIAdvisor, doUserOperation } : CrystalProps) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLVideoElement>(null)
  const wrapRef = useRef<HTMLDivElement>(null)
  const [isCanplaythrough, setIsCanplaythrough] = useState(false);

  // AIアドバイザーの能動的なメッセージが存在するかどうか
  const hasProactiveMessage = AdvisorState.hasLlmAdvisorMessage

  const play = useCallback((maxRetry = 5, wait = 5000, currentRetry = 0) => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;
    // 再生を試みる
    if(ref.current) {
      ref.current.play().catch((e) => {
        console.error('crystal play error', e)
        // 再生が失敗した場合、再試行回数を増やす
        currentRetry++;

        // 最大再試行回数をオーバーした場合、関数の実行を停止する
        if(currentRetry > maxRetry){
          return;
        }

        // 再試行回数が最大回数を超えていない場合、指定した時間後に再度再生を試みる
        timeoutId = setTimeout(() => {
          play(maxRetry, wait, currentRetry);
        }, wait);
      });
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    const elem = ref.current;
    if (elem) {
      ref.current.oncanplaythrough = () => {
        setIsCanplaythrough(true);
      };
    }
    return () => {
      if (elem) {
        elem.oncanplaythrough = () => {};
      }
    }
  }, [])

  useEffect(() => {
    if (isCanplaythrough && doUserOperation && ref.current) {
      play();

      const audioArray = new Uint8Array(audioMasterAnalyserBus.frequencyBinCount)
      const element = ref.current
      const handle = setInterval(() => {
        onUpdate(element, audioArray)
      }, 16)

      return () => clearInterval(handle)
    }
  }, [doUserOperation, isCanplaythrough, play])

  const handleHover = (enter: boolean) => {
    if (wrapRef.current) {
      const element = wrapRef.current
      const zoomLevel = enter ? 105 : 100;
      element.style.scale = `${zoomLevel}%`
    }
  }

  return (
    <div ref={wrapRef} className="col-4 d-flex justify-content-center align-items-end">
      <video
        ref={ref}
        src="/crystal_graphics.mp4" loop
        width="100%"
        preload="auto"
        muted
        disablePictureInPicture
        playsInline
        style={{
          mask: 'radial-gradient(#fff 50%, rgba(0, 0, 0, 0) 70%)',
          cursor: "pointer",
        }}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        onClick={onClickCrystal}
      />
      { enableAIAdvisor && <button
          onClick={onClickCrystal}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          className={`btn btn-primary accuracy_button${hasProactiveMessage ? ' standby' : ''}`}>{t('お話してもよろしいですか？')}</button>}
    </div>
  )
});
