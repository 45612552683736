import './UserInfoModal.css'

import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'

import { LocalStorage } from '../store/LocalStorage'
import { LanguageFlag } from './LanguageFlag'
import { ILanguages, ILanguageObject, LanguageList, guestLanguage } from '../util/language';
import { LanguageSelect } from './LanguageSelect'
import { useTranslation } from 'react-i18next'

export const UserInfoModal = observer(({ open, languageListOpen, onClose }: { open?: boolean, languageListOpen?: boolean, onClose?: () => void }) => {
  const { t } = useTranslation()
  const myModal = useRef<any>()

  const [name, setName] = useState(LocalStorage.name || '')
  const [langSelect, setLangSelect] = useState(languageListOpen)
  const [autoStart, setAutoStart] = useState(!!LocalStorage.autoStart)
  const [ready, setReady] = useState(false)

  const leftFlagLanguage: ILanguageObject[] = LocalStorage.language ? LocalStorage.language.map(l => LanguageList[l]) : [LanguageList[ILanguages["ja-JP"]]];

  useEffect(() => {
    setLangSelect(languageListOpen)
  }, [languageListOpen])

  useEffect(() => {
    setReady(!!name.trim())
  }, [name])

  const handleNameChange = useCallback((e: any) => {
    setName(e.target.value)
  }, [])

  // get browser langauge if needed if the user
  // accessed it for the very first time.
  useEffect(() => {
    if (!LocalStorage.name && !LocalStorage.language) {
      const language: ILanguages = navigator.language || (navigator as any).userLanguage
      const langObj = guestLanguage(language)
      if (langObj) {
        LocalStorage.setValue('language', [langObj.id])
      }
    }
  }, [])

  useEffect(() => {
    if (!myModal.current) {
      myModal.current = new window.bootstrap.Modal(document.getElementById('userInfo'), {
        keyboard: false
      })
    }

    if (open) {
      myModal.current.show()
    } else {
      myModal.current.hide()
    }
  }, [onClose, open])

  const handleSelectedLanguageClick = useCallback(() => {
    setLangSelect((select) => !select)
  }, [])

  const handleLanguageSelect = useCallback(() => {
    if(LocalStorage.language.length === 4){
      setLangSelect(false)
    }
  }, [])

  const onNameInputFocus = useCallback(() => {
    setLangSelect(false)
  }, [])

  const handleSaveInfo = useCallback(() => {
    LocalStorage.setValue('name', name.trim())
    LocalStorage.setValue('autoStart', autoStart)
    if(LocalStorage.language.length === 0){
      LocalStorage.setValue('language', [ILanguages["ja-JP"]])
    }
    onClose && onClose()
  }, [autoStart, name, onClose])

  const toggleAutoStart = useCallback(() => {
    setAutoStart((auto) => !auto)
  }, [])

  const toggleSaveInfo = useCallback(() => {
    LocalStorage.setValue('saveInfo', !LocalStorage.saveInfo)
  }, [])

  return (
    <div className='modal fade' id='userInfo' data-bs-backdrop='static'
      data-bs-keyboard='false' tabIndex={-1} aria-labelledby='userInfoLabel'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content' style={{ borderRadius: '1rem' }}>
          <div className='modal-header border-bottom-0'>
            <h5 className='modal-title' id='userInfoLabel'>
              {t('表示名・言語設定')}
            </h5>
            {/* <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button> */}
          </div>
          <div className='modal-body'>
            <div className='container' style={{ display: 'flex', flexDirection: 'column', maxHeight: 'calc(100vh - 2.75rem * 2 - 138px)' }}>
              <div className='row align-items-center mb-2'>
                <div className='col-3'>
                  <span>{t('表示名')}</span>
                </div>
                <div className='col'>
                  <input className='form-control' placeholder={LocalStorage.name || t('表示名をご入力ください')}
                    value={name} onChange={handleNameChange} onFocus={onNameInputFocus} />
                </div>
              </div>
              <div className='row align-items-center mb-2'>
                <div className='col-3'>
                  {t('言語')}
                </div>
                <div className='col d-flex align-items-center'>
                  <>
                    {leftFlagLanguage.map((lang, index) =>
                      <LanguageFlag key={index} className='selected-language'
                                    language={lang}
                                    onClick={handleSelectedLanguageClick} />)}
                  </>
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col'>
                  <div className='form-check'>
                    <input id='autoStartCheck' className='form-check-input' type='checkbox' checked={autoStart} onChange={toggleAutoStart} />
                    <label className='form-check-label' htmlFor='autoStartCheck'>
                      {t('自動でマイクをオンにする')}
                    </label>
                  </div>
                </div>
              </div>
              {langSelect && (
                <>
                  <div className='col-12 text-center text-secondary h5'>
                    {t('言語選択')} {t('（※）最大３言語選択可能です。')}
                  </div>
                  <div className='row border rounded pt-3' style={{ flex: 1, overflowY: 'auto' }}>
                    <LanguageSelect onLanguageSelected={handleLanguageSelect} />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='modal-footer'>
            <div className='form-check'>
              <input id='saveInfoCheck' className='form-check-input' type='checkbox' checked={LocalStorage.saveInfo} onChange={toggleSaveInfo} />
              <label className='form-check-label' htmlFor='saveInfoCheck'>
                {t('表示名と言語を保存')}
              </label>
            </div>
            <button type='button' className='btn btn-primary'
              disabled={!ready} onClick={handleSaveInfo}>
              {t('OK')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})
