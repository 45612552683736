import { ILanguages, LanguageList } from './language'
import { playAzureAudio } from './playAzureAudio'
import { playGoogleAudio } from './playGoogleAudio'
import { audioContext } from './audioContext'

export const textToSpeech = async (text: string, languageCode: ILanguages): Promise<AudioBuffer | null> => {
  let arrayBuffer = new ArrayBuffer(0)
  try {
    const { isOnlyAzure, ttsLanguageCode } = LanguageList[languageCode]
    // if Text-To-Speech is not supported
    if(ttsLanguageCode) {
      if (isOnlyAzure) {
        arrayBuffer = await playAzureAudio(text, languageCode)
      } else {
        arrayBuffer = await playGoogleAudio(text, languageCode)
      }
      return await audioContext.decodeAudioData(arrayBuffer);
    }
  } catch (err) {
    console.warn(err)
  }

  return null;
}
