import { makeAutoObservable } from 'mobx'

import { ILanguages } from '../util/language'
import { randomId } from '../util/util'

const DEFAULT_STORAGE = 'clipear_settings'

type MeetingState = "connecting" | "waiting" | "incall" | "completed" | "notexist"

export class StorageClass {
  storageName: string
  uid?: string
  name?: string
  language: ILanguages[] = [ILanguages["ja-JP"]]
  autoStart: boolean
  roomState: MeetingState = "connecting"
  endTime:   Date | null = null
  translationLanguage: ILanguages = ILanguages["ja-JP"]
  speakTranslateLocal = false
  saveInfo = true
  tosAccepted = false
  preAccepted = false
  ownerId?: string

  private static IGNORE_WITH_INFO: (keyof StorageClass)[] = [
    'uid', 'name', 'language'
  ]

  private static IGNORE: (keyof StorageClass)[] = [
    'storageName', 'roomState', 'translationLanguage',
    'speakTranslateLocal', 'endTime',
  ]

  get roomClosed(): boolean {
    return this.roomState === 'completed' || this.roomState === 'waiting' || this.roomState === 'connecting' || this.roomState === 'notexist'
  }

  constructor(storageName = DEFAULT_STORAGE) {
    this.storageName = storageName
    // default to true
    this.autoStart = true

    // load data
    this.load()

    // fix language array
    if(!Array.isArray(this.language)){
      this.language = this.language ? [this.language] : [ILanguages["ja-JP"]];
    }

    if (!this.uid) {
      this.uid = randomId(24)
      this.save()
    }

    makeAutoObservable(this)
  }

  private load = () => {
    const rawObj = JSON.parse(localStorage.getItem(this.storageName) || '{}')
    Object.assign(this, rawObj)
  }

  setValue = (key: keyof StorageClass, value: any) => {
    (this[key] as any) = value
    this.save()
    const event = new Event('localstorage-change-' + key);
    window.dispatchEvent(event);
  }

  save = () => {
    try {
      const objToSave: any = {}
      const ignore = this.saveInfo ? StorageClass.IGNORE : [...StorageClass.IGNORE, ...StorageClass.IGNORE_WITH_INFO]
      Object.entries(this).forEach(([k, v]) => {
        if (typeof v !== 'function' && !ignore.includes(k as keyof StorageClass)) {
          objToSave[k] = v
        }
      })
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(this.storageName, JSON.stringify(objToSave))
      } else {
        console.warn('localStorage is not available.');
      }
    } catch (e) {
      console.error('Failed to save to localStorage:', e);
    }
  }
}

export const LocalStorage = new StorageClass()
