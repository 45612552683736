import { createContext, useContext, useEffect, useId, useRef } from "react"

const ModalContext = createContext('')

const Header = ({ children, showCloseButton, onClose }: { children: React.ReactNode, showCloseButton?: boolean, onClose?: () => void }) => {
  const id = useContext(ModalContext)
  const handleClose = onClose || (() => {});

  return (<div className='modal-header border-bottom-0'>
    <h5 className='modal-title' id={`${id}-label`}>
      {children}
    </h5>
    {showCloseButton && (
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
    )}
  </div>)
}

const Body = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='modal-body'>
      { children }
    </div>
  )
}

const Footer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='modal-footer'>
      { children }
    </div>
  )
}

type ModalRootProps = {
  open?: boolean,
  onClose?: () => void,
  children?: React.ReactNode
  disableBackgroundClick?: boolean,
}

/**
 * Basic Modal Component.
 *
 * @usage
 * ```jsx
 * import { Modal } from "Modal"
 *
 * <Modal.Root open={open} onClose={() => console.log('cowabunga')}>
 *   <Modal.Header> Header! </Modal.Header>
 *   <Modal.Body> Body! </Modal.Body>
 *   <Modal.Footer> Footer! </Modal.Footer>
 * </Modal.Root>
 * ```
 */
const Root = ({ open, onClose, children, disableBackgroundClick = true }: ModalRootProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const myModal = useRef<any>(null)
  const id = useId()

  const isOpen = !!open

  useEffect(() => {
    if (!myModal.current) {
      if (divRef.current) {
        myModal.current = new window.bootstrap.Modal(divRef.current, {
          keyboard: false,
          backdrop: disableBackgroundClick ? 'static' : true
        })
      }
    }
  }, [disableBackgroundClick])

  useEffect(() => {
    if (!isOpen) {
      onClose && onClose()
      myModal.current?.hide()
    } else {
      myModal.current?.show()
    }
  }, [onClose, isOpen])

  return (
    <ModalContext.Provider value={id}>
      <div ref={divRef} className='modal fade' id={id} data-bs-backdrop={disableBackgroundClick ? 'static' : true}
        data-bs-keyboard='false' tabIndex={-1} aria-labelledby={`${id}-label`}>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content' style={{ borderRadius: '1rem' }}>
            {children}
          </div>
        </div>
      </div>
    </ModalContext.Provider>
  )
}

export const Modal = {
  Root, Header, Body, Footer
}
