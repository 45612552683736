import { useCallback, useEffect, useRef, useState } from 'react';
import MediaStreamRecorder from 'msr';
import useQuery from './useQuery';
import { useSttDictionaries } from './useSttDictionaries';
import { getTranslateCodes } from '../util/language';
import { sentryLog } from '../util/sentry';
import { HALLUCINATION_WORDS, isNotProduction } from '../util/util';
import { UseSpeechArgs, UseSpeechHandles, InitParam, ServerEvent, VoiceChunk } from '../types/speech';

/**
 * マイクからの音声の録音と解析を行うためのReactフックの表現。
 *
 * @param {Object} UseSpeechArgs - フック設定のためのパラメータ。
 * @param {string} UseSpeechArgs.userId - ユーザーID。
 * @param {string} UseSpeechArgs.roomOwnerId - ルームオーナーのID。
 * @param {boolean} UseSpeechArgs.needInterim - 中間結果が必要かどうか。
 * @param {ref} UseSpeechArgs.clusterAmount - STTに送信する話者の数。
 * @param {function} UseSpeechArgs.onMessage - メッセージ処理用の関数。
 * @param {function} UseSpeechArgs.onDataAvailable - 録音データが利用可能になった際の処理を行う関数。
 * @param {function} UseSpeechArgs.onError - エラー発生時の処理を行う関数。
 *
 * @returns {Object} UseSpeechHandles - 録音プロセス用のコールバック関数と状態変数を含むオブジェクト。
 * @returns {boolean} UseSpeechHandles.initialized - 初期化が完了し、接続が確立しているかどうかを示すブール値。
 * @returns {Object} UseSpeechHandles.initializedRef - 接続が確立しているかどうかを参照するrefオブジェクト。
 * @returns {function} UseSpeechHandles.start - 音声文字変換処理を開始するための関数。
 * @returns {function} UseSpeechHandles.stop - 音声文字変換処理を停止するための関数。
 * @returns {function} UseSpeechHandles.setUseStart - このhookを使用するかどうかを設定するための関数。
 */
export const useCustomSpeech = ({
  userId,
  roomOwnerId,
  needInterim,
  clusterAmount,
  onMessage,
  onDataAvailable,
  onError,
}: UseSpeechArgs): UseSpeechHandles => {
  // hookのイベントハンドラ
  const handlersRef = useRef({
    onMessage,
    onError
  });
  useEffect(() => {
    handlersRef.current = {onMessage, onError};
  }, [onMessage, onError]);

  // 起動するかどうか
  const [useStart, setUseStart] = useState<boolean>(false);

  // ソケット通信
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const socketRef = useRef(socket);
  useEffect(() => {
    socketRef.current = socket;
  }, [socket]);

  // ソケット通信開通したかどうか
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const isSocketConnectedRef = useRef(isSocketConnected);
  useEffect(() => {
    isSocketConnectedRef.current = isSocketConnected;
  }, [isSocketConnected]);

  // レコーダー
  const mediaRecorderRef = useRef<MediaStreamRecorder>();

  // 再接続するまでの待機時間
  const retryTimeoutRef = useRef(1000); // 初期遅延時間は1秒
  // 再接続中フラグ
  const reconnectingRef = useRef(false);

  // 辞書適用関数
  const {replaceTextByDictionaries} = useSttDictionaries(roomOwnerId);

  // GETパラメータの取得
  const { getSttURL } = useQuery();
  // STTのURLを取得
  const sttURL = getSttURL();

  const recognizing = useCallback( async (text: string) => {
    if(text !== ''){
      // 簡易的なハルシネーション対策
      if (HALLUCINATION_WORDS.some(n => n.test(text))) {
        console.debug('find hallucination word', text);
        return;
      }
      // isFinal が false の場合はドラフト文。 true の場合は大きいモデルの文字起こしテキスト（最終結果）
      handlersRef.current.onMessage(text, false);
    }
  }, []);

  const recognized = useCallback( async (text: string, language: string, talkId: string, speakerId: string) => {
    if(text !== ''){
      // 簡易的なハルシネーション対策
      if (HALLUCINATION_WORDS.some(n => n.test(text))) {
        console.debug('find hallucination word', text);
        return;
      }
      // 辞書登録に従い言葉を置換
      const replaced = await replaceTextByDictionaries(text);
      // isFinal が true の場合は最終結果。
      handlersRef.current.onMessage(replaced, true, language, talkId, speakerId);
    }
  }, [replaceTextByDictionaries]);

  useEffect(() => {
    if(useStart){
      const connect = () => {
        // すでに接続している場合は処理しない
        if(socketRef.current){
          return socketRef.current;
        }

        // 開発・ステージング環境のみログ出力
        if (isNotProduction()) {
          console.log('WebSocket STT URL', sttURL);
        }

        const newSocket = new WebSocket(sttURL);
        setSocket(newSocket);

        const initParam: InitParam = {
          signal: "init_params",
          params: {
            connectionId: userId, // STTに送信する接続ID（会議ID + 参加ユーザーID）
            ioVersion: "v0.1",
            allowableDistance: 0.6,
          }
        }
        console.log(initParam);

        // Initial threshold setup?
        newSocket.onopen = () => {
          newSocket.send(JSON.stringify(initParam));
          setIsSocketConnected(true); // ソケット通信開始
          retryTimeoutRef.current = 1000; // 接続成功時にリセット
          reconnectingRef.current = false; // 再接続成功時にフラグをリセット
        }

        // 再接続ロジック
        const attemptReconnect = () => {
          // onerror と onclose が同時に発生した場合に2回再接続を行わないようにする
          if (reconnectingRef.current) return;
          // 再接続中フラグ:ON
          reconnectingRef.current = true;
          setSocket(null);
          retryTimeoutRef.current = Math.min(retryTimeoutRef.current + 1000, 10000); // 遅延時間を1秒増やし、最大10秒

          setTimeout(() => {
            console.log('Reconnecting...');
            // 再接続中フラグ:OFF
            reconnectingRef.current = false;
            connect();
          }, retryTimeoutRef.current);
        };

        newSocket.onerror = (error) => {
          console.error('WebSocket error:', error);
          attemptReconnect();
        };

        newSocket.onclose = (event) => {
          console.log('WebSocket closed:', event);
          attemptReconnect();
        };
      }
      connect();
    }
  }, [sttURL, useStart, userId]);

  const recorderStop = useCallback(() => {
    if(mediaRecorderRef.current){
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.ondataavailable = (blob: Blob) => {};
    }
  }, []);

  const recorderStart = useCallback((stream: MediaStream) => {
    recorderStop();

    mediaRecorderRef.current = new MediaStreamRecorder(stream);
    mediaRecorderRef.current.stream = stream;
    mediaRecorderRef.current.recorderType = MediaStreamRecorder.StereoAudioRecorder;
    mediaRecorderRef.current.mimeType = 'audio/wav';
    mediaRecorderRef.current.audioChannels = 1; // モノラル

    mediaRecorderRef.current.ondataavailable = async (blob: Blob) => {
      try {
        const base64Data = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve((reader.result as string).split(',')[1]);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(blob);
        });

        const languageConstraint = getTranslateCodes();

        const dataChunk: VoiceChunk = {
          signal: "voice_chunk",
          data:   base64Data!,
          options: {
            connectionId: userId, // STTに送信する接続ID（会議ID + 参加ユーザーID）
            languages: languageConstraint,
            numOfSpeakers: clusterAmount?.current ?? 1,
          }
        }

        const data = JSON.stringify(dataChunk)

        if(socketRef.current && socketRef.current.readyState === WebSocket.OPEN){
          socketRef.current.send(data);
        }

        if(process.env.REACT_APP_ENVIRONMENT !== "production"){
          await onDataAvailable(blob);
        }
      } catch (e) {
        console.error(e);
      }
    }
    mediaRecorderRef.current.start(1000);
  }, [clusterAmount, onDataAvailable, recorderStop, userId]);

  useEffect(() => {
    const messageFunc = async (event: any) => {
      const receivedData = event.data
      const message: ServerEvent | null = receivedData != null ? JSON.parse(receivedData) : null
      if (message == null) {
        return
      }
      console.log(message)

      switch(message.signal) {
        case "message":
          console.log(message.message)
          break

        case "drafTtext":
        {
          if(!needInterim){
            break;
          }
          // ドラフト文（※大きいモデルが文字起こしをするまで、小さいモデルが出力するテキストを受信できる。）
          const data = message.data.results.length > 0 ? message.data.results[0].text : '';
          await recognizing(data)
        } break

        case "newData":
          for (const { talkLabel, speakerLabel, text } of message.data.results) {
            await recognized(text, message.data.language, talkLabel, speakerLabel);
          }
          break

        case "error":
          console.error("Error Occurred: ", message.message)
          const error = new Error(message.message)
          sentryLog(error)
          break

        case "heartbeat":
          break

        case "labels":
          break
      }
    }

    if(socket){
      socket.addEventListener("message", messageFunc)
    }

    return () => {
      if(socket){
        socket.removeEventListener("message", messageFunc)
      }
    }
  }, [needInterim, recognized, recognizing, socket]);

  // 音声ストリームを受け取り、認識を開始する関数
  const start = useCallback( async (stream: MediaStream) => {
    console.log('startCustomCloudStream ==')
    recorderStart(stream);
  }, [recorderStart]);

  // 認識を停止する関数
  const stop = useCallback(() => {
    console.log('stopCustomCloudStream')
    recorderStop();
  }, [recorderStop])

  return {
    initialized: isSocketConnected,
    initializedRef: isSocketConnectedRef,
    start,
    stop,
    setUseStart,
  }
}
