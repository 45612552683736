import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguageAgendaList, isDefaultMinutes } from '../util/util';
import { TypeaheadRef } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { AgendaState, AgendaValidationError, AgendaValidationResult } from '../types/agenda';
import { AgendaSelectHandles } from '../component/AgendaSelect';
import { MinutesListHandles } from '../component/MinutesList';
import { updateUserLanguage } from './useUserLanguage';

export const useAgendaManagement = (
  roomOwnerId: string,
  meetingId: string,
  initialAgenda: string,
  onAgendaChange: (targetAgenda: string) => void
) => {
  const { t } = useTranslation();
  const [state, setState] = useState<AgendaState>({
    agenda: [],
    agendaValue: initialAgenda,
    typeaheadShow: true
  });

  // Refs
  const agendaSelectRef = useRef<AgendaSelectHandles>(null);
  const typeaheadRef = useRef<TypeaheadRef>(null);
  const minutesListRef = useRef<MinutesListHandles>(null);

  const updateAgendaList = useCallback((newAgenda: string[]) => {
    setState(prev => ({
      ...prev,
      agenda: newAgenda
    }));
  }, []);

  const handleLanguageChange = useCallback((lng: string) => {
    document.documentElement.setAttribute('lang', lng);
    setState(prev => ({
      ...prev,
      agenda: changeLanguageAgendaList(lng, prev.agenda)
    }));
    updateUserLanguage(roomOwnerId, meetingId, lng);
  }, [roomOwnerId, meetingId]);

  const handleAgendaChange = useCallback((targetAgenda: string, notifyServer: boolean) => {
    if (targetAgenda !== state.agendaValue) {
      if (notifyServer) {
        // 親コンポーネントに通知
        onAgendaChange(targetAgenda);
      } else {
        setState(prev => ({
          ...prev,
          typeaheadShow: false
        }));

        queueMicrotask(() => {
          setState(prev => ({
            ...prev,
            agendaValue: targetAgenda,
            typeaheadShow: true
          }));

          queueMicrotask(() => {
            // Typeaheadの選択状態を更新
            if (typeaheadRef.current) {
              typeaheadRef.current.clear();
              // 必要に応じて新しい値を設定
              if (targetAgenda) {
                typeaheadRef.current.setState({ text: targetAgenda });
              }
            }

            // AgendaSelectの状態を更新
            if (agendaSelectRef.current) {
              agendaSelectRef.current.setAgendaSelected(targetAgenda);
              agendaSelectRef.current.setAgendaEditValue('');
            }

            // MinutesListの状態を更新
            if (minutesListRef.current) {
              minutesListRef.current.setAgenda(targetAgenda);
            }
          });
        });
      }
    }
  }, [onAgendaChange, state.agendaValue]);

  const handleAgendaSelect = useCallback((selected: Option[]) => {
    const selectAgenda = selected[0] as string;
    if (!selectAgenda) {
      return;
    }

    if (selectAgenda !== '') {
      agendaSelectRef.current?.setAgendaSelected(selectAgenda);
    } else if (!isDefaultMinutes(selectAgenda)) {
      agendaSelectRef.current?.setAgendaSelected('');
    }
    agendaSelectRef.current?.setAgendaEditValue('');

    if (state.agenda.includes(selectAgenda)) {
      minutesListRef.current?.setAgenda(selectAgenda);
      onAgendaChange(selectAgenda);
    }
  }, [onAgendaChange, state.agenda]);

  const handleAgendaInput = useCallback((text: string) => {
    agendaSelectRef.current?.setAgendaEditValue(text);
  }, []);

  const validateAgendaOperation = useCallback((
    operation: 'add' | 'edit' | 'delete',
    value: string,
    targetAgenda?: string
  ): AgendaValidationResult<string, AgendaValidationError> => {
    const trim = value.trim();

    // 削除操作の検証
    if (operation === 'delete') {
      if (isDefaultMinutes(value)) {
        return { ok: false, error: { message: t('「{{agenda}}」は削除できません', { agenda: value }) } };
      }
      if (!state.agenda.includes(value)) {
        return { ok: false, error: { message: t('議題「{{agenda}}」はすでに削除されたか、存在していません', { agenda: value }) } };
      }
      return { ok: true, value };
    }

    // 共通の入力検証
    if (trim === '') {
      return { ok: false, error: { message: t('議題を入力して下さい') } };
    }

    if (isDefaultMinutes(trim)) {
      return { ok: false, error: { message: t('「{{agenda}}」以外の議題を入力して下さい', { agenda: trim }) } };
    }

    if (state.agenda.includes(trim) && trim !== targetAgenda) {
      return { ok: false, error: { message: t('議題「{{agenda}}」はすでに存在しています', { agenda: trim }) } };
    }

    // 編集操作の追加検証
    if (operation === 'edit' && !state.agenda.includes(targetAgenda!)) {
      return { ok: false, error: { message: t('編集前の議題「{{agenda}}」はすでに削除されたか、存在していません', { agenda: targetAgenda }) } };
    }

    return { ok: true, value: trim };
  }, [state.agenda, t]);

  return {
    state,
    refs: {
      agendaSelectRef,
      typeaheadRef,
      minutesListRef
    },
    actions: {
      updateAgendaList,
      handleLanguageChange,
      handleAgendaChange,
      handleAgendaSelect,
      handleAgendaInput,
      validateAgendaOperation
    }
  };
};
