import './TroubleshootingModal.css'

import { observer } from 'mobx-react-lite'
import {useCallback, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next'

import { Troubleshooting } from './Troubleshooting'

export const TroubleshootingModal = observer(({ open, onClose }: { open?: boolean, onClose?: () => void }) => {
  const { t } = useTranslation()
  const myModal = useRef<any>()

  useEffect(() => {
    if (!myModal.current) {
      myModal.current = new window.bootstrap.Modal(document.getElementById('troubleshooting'), {
        keyboard: false
      })
    }

    if (open) {
      myModal.current.show()
    }else{
      myModal.current.hide()
    }
  }, [onClose, open])

  const handleTroubleshootingModalClose = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  return (
    <div className='modal fade' id='troubleshooting' data-bs-backdrop='static'
      data-bs-keyboard='false' tabIndex={-1} aria-labelledby='troubleshootingModal'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content' style={{ borderRadius: '1rem' }}>
          <div className='modal-header border-bottom-0'>
            <button type='button' className='btn-close' onClick={handleTroubleshootingModalClose}></button>
          </div>
          <div className='modal-body'>
            <div className='container'
                 style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 2.75rem * 2 - 138px)'}}>
              <Troubleshooting title={t('トラブルシューティング')} />
            </div>
          </div>
          <div className='modal-footer'>
          <button type='button' className='btn btn-primary' onClick={handleTroubleshootingModalClose}>
              {t('OK')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})
