import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useQuery from '../hook/useQuery';
import { useTranslation } from 'react-i18next';
import { useSttType } from '../types/speech';

export const SttChangeModal = observer(({ open, onClose }: { open?: boolean, onClose?: () => void }) => {
  const { t } = useTranslation()

  const myModal = useRef<any>()
  const [customUrl, setCustomUrl] = useState("");

  const { paramChangeSTTWindow, paramChangeSTTTypeWindow, getSttURL, getSttType } = useQuery();

  useEffect(() => {
    if (!myModal.current) {
      myModal.current = new window.bootstrap.Modal(document.getElementById('stt_change'), {
        keyboard: false
      })
    }

    if (open) {
      myModal.current.show()
    }else{
      myModal.current.hide()
    }
  }, [onClose, open])

  const handleSettChangeSwitchClose = useCallback((changeType: useSttType, changeUrl?: string) => {
    try {
      const url = new URL(window.location.href);
      switch (changeType){
        case useSttType.Custom:
          // 不正なURLの場合はエラー
          new URL(changeUrl!);
          // URLを組み立て
          url.searchParams.set(paramChangeSTTTypeWindow, String(useSttType.Custom));
          url.searchParams.set(paramChangeSTTWindow, changeUrl!);
          break;
        case useSttType.Google:
          // URLを組み立て
          url.searchParams.set(paramChangeSTTTypeWindow, String(useSttType.Google));
          break;
        case useSttType.Azure:
          // URLを組み立て
          url.searchParams.set(paramChangeSTTTypeWindow, String(useSttType.Azure));
          break;
      }
      // 指定STT付きURLに遷移
      window.location.href = url.toString();
    } catch (e) {
      alert(t('URLが不正です'));
    }
  }, [paramChangeSTTTypeWindow, paramChangeSTTWindow, t])

  const handleSttChangeCancelClose = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  return (
    <div className='modal fade' id='stt_change' data-bs-backdrop='static'
      data-bs-keyboard='false' tabIndex={-1} aria-labelledby='sttChangeModal'>
      <div className='modal-dialog'>
        <div className='modal-content' style={{ borderRadius: '1rem' }}>
          <div className='modal-header border-bottom-0'>
            <h5 className='modal-title' id='sttChangeModal'>{t('STTのURL変更')}</h5>
            <button type='button' className='btn-close' onClick={handleSttChangeCancelClose}></button>
          </div>
          <div className='modal-body'>
            <button type='button' className='btn btn-primary w-100 mb-3'
                    onClick={() => handleSettChangeSwitchClose(useSttType.Azure)}>
              {t('Azure STT')}{useSttType.Azure === getSttType() ? t('（※選択中※）') : ''}
            </button>
            <button type='button' className='btn btn-primary w-100 mb-3'
                    onClick={() => handleSettChangeSwitchClose(useSttType.Google)}>
              {t('Google STT')}{useSttType.Google === getSttType() ? t('（※選択中※）') : ''}
            </button>
            <button type='button' className='btn btn-success w-100 mb-3'
                    onClick={() => handleSettChangeSwitchClose(useSttType.Custom, process.env.REACT_APP_STAGING_SPEAKER_DIARIZATION_URL!)}>
              {t('開発・ステージング環境用')}{useSttType.Custom === getSttType() && process.env.REACT_APP_STAGING_SPEAKER_DIARIZATION_URL === getSttURL() ? t('（※選択中※）') : ''}
            </button>
            <button type='button' className='btn btn-danger w-100 mb-3'
                    onClick={() => handleSettChangeSwitchClose(useSttType.Custom, process.env.REACT_APP_PROD_SPEAKER_DIARIZATION_URL!)}>
              {t('本番環境用')}{useSttType.Custom === getSttType() && process.env.REACT_APP_PROD_SPEAKER_DIARIZATION_URL === getSttURL() ? t('（※選択中※）') : ''}
            </button>
            <div className="row g-3">
              <div className="col-8">
                <label htmlFor="customUrl" className="visually-hidden">{t('URL入力')}</label>
                <input type="text" className="form-control" id="customUrl" placeholder={t('URLを入力...')}
                       value={customUrl}
                       onChange={(event) => setCustomUrl(event.target.value)} />
              </div>
              <div className="col-auto">
                <button type="button" className="btn btn-primary mb-3"
                        onClick={() => handleSettChangeSwitchClose(useSttType.Custom, customUrl)}>{t('入力URLを使用')}
                </button>
              </div>
            </div>
            <button type='button' className='btn btn-secondary w-100 mb-3'
                    onClick={() => handleSttChangeCancelClose()}>
              {t('変更しない')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
});
