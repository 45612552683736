import { makeAutoObservable, observable } from 'mobx'
import { LocalStorage } from './LocalStorage';
import { SYSTEM_USER_ID } from '../util/util';
// import { _randomId } from '../util/util'

const USER_ID = 'local_user_uniq'

// mock data
// import MESSAGES from './mockData.json'

class Message implements ChatMessage {
  id: string;
  text: string;
  sourceLanguage: string;
  createdAt?: Date;
  userId: string | number;
  displayName?: string;
  speakerId?: string;
  translated?: string;
  translatedLang?: string;
  translations: { [lang: string]: string };
  talkLabel?: string;

  constructor(data: ChatMessage) {
    this.id = data.id
    this.text = data.text
    this.talkLabel = data.talkLabel
    this.sourceLanguage = data.sourceLanguage
    this.createdAt = data.createdAt
    this.userId = data.userId
    this.displayName = data.displayName
    this.translated = data.translated
    this.translatedLang = data.translatedLang
    this.translations = data.translations
    this.speakerId = data.speakerId

    makeAutoObservable(this)
  }

  setText = (t: string) => {
    this.text = t
  }

  addTranslation = (lang: string, text: string) => {
    this.translations = {
      ...this.translations,
      [lang]: text,
    }
  }
}

export class MessagesClass {
  data = observable.map<string, Message>()

  constructor() {
    makeAutoObservable(this)
  }

  setData = (messages: Message[]) => {
    this.data.clear()
    messages.forEach((message) => {
      this.data.set(message.id, message)
    })
  }

  add = (message: ChatMessage) => {
    this.data.set(message.id, new Message(message))
  }

  get = (messageId: string) => {
    return this.data.get(messageId)
  }

  remove = (messageId: string) => {
    this.data.delete(messageId)
  }

  addTranslation = (id: string, lang: string, text: string) => {
    this.data.get(id)?.addTranslation(lang, text);
  }

  changeName = (userId: string, newName: string) => {
    this.data.forEach((msg) => {
      if (msg.userId === userId) {
        msg.displayName = newName
      }
    })
  }

  changeLabel = (userId: string, idMap: { [id: string]: string }) => {
    this.data.forEach((msg) => {
      if (msg.userId === userId) {
        if (msg.talkLabel && idMap[msg.talkLabel] && msg.speakerId) {
          if(msg.speakerId !== msg.userId + '/' + idMap[msg.talkLabel]){
            console.log('label change', msg.talkLabel, msg.speakerId, msg.userId + '/' + idMap[msg.talkLabel])
            msg.speakerId = msg.userId + '/' + idMap[msg.talkLabel];
          }
        }
      }
    })
  }

  getAllMessages = (isExcludeAdvisor: boolean = true, language?: string) => {
    const messages = Array.from(Messages.data.values())
    const list: { user: string, messages: { [lang: string]: string } }[] = []

    const names: { [id: string]: string | null } = {
      // name for local user
      [LocalStorage.uid || USER_ID]: LocalStorage.name || LocalStorage.uid || USER_ID,
    }

    const collectName = (msg: Message) => {
      if (!names[msg.userId]) {
        names[msg.userId] = null
      }
      if (msg.displayName) {
        names[msg.userId] = msg.displayName
      }
    }

    for (const msg of messages) {
      if(isExcludeAdvisor && msg.userId === SYSTEM_USER_ID){
        continue;
      }

      let hasMsg = false
      const msgInfo: { user: string, messages: { [lang: string]: string } } = {
        user: `${msg.displayName || msg.userId}`,
        messages: {},
      }

      if (!language || msg.sourceLanguage === language) {
        // collect source languages
        hasMsg = true
        msgInfo.messages[msg.sourceLanguage] = msg.text

        collectName(msg)
      }

      if (msg.translatedLang && msg.translated && (!language || msg.translatedLang === language)) {
        // collect translated languages
        hasMsg = true
        msgInfo.messages[msg.translatedLang] = msg.translated

        collectName(msg)
      }

      // collect translated languages
      if (msg.translations && (!language || msg.translations[language])) {
        hasMsg = true
        msgInfo.messages = {
          ...msgInfo.messages,
          ...(language ? { [language]: msg.translations[language] } : { ...msg.translations }),
        }

        collectName(msg)
      }

      if (hasMsg) {
        list.push(msgInfo)
      }
    }

    // fix username
    list.forEach((item) => {
      if (names[item.user]) {
        item.user = names[item.user]!
      }
    })

    return list
  }
}

const Messages = new MessagesClass()
// Messages.setData(MESSAGES)

// realtime mock data
// let id = MESSAGES.length
// const mock1 = {
//   id: "0",
//   text: "Hello",
//   userId: 1,
//   sourceLanguage: "en-US"
// }

// const mock2 = {
//   id: '1',
//   text: 'Hello there!',
//   userId: 'Test',
//   sourceLanguage: 'en-US'
// }

// setInterval(() => {
//   Messages.add({
//     ...(id % 2 ? mock2 : mock1),
//     id: `${id++}`,
//   })
// }, 2000)

// setInterval(() => {
//   Messages.add({
//     ...mock2,
//     id: _randomId(),
//   })
// }, 30000)

export default Messages
