import { observer } from 'mobx-react-lite'
import React, {useCallback, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next'

export const ConfirmMeetingActiveModal = observer(({ open, onClose }: { open?: boolean, onClose?: (close: boolean) => void }) => {
  const { t } = useTranslation()
  const myModal = useRef<any>()

  useEffect(() => {
    if (!myModal.current) {
      myModal.current = new window.bootstrap.Modal(document.getElementById('confirm_meeting_active'), {
        keyboard: false
      })
    }

    if (open) {
      myModal.current.show()
    }else{
      myModal.current.hide()
    }
  }, [onClose, open])

  const handleConfirmMeetingActiveModalEndClose = useCallback(() => {
    onClose && onClose(true)
  }, [onClose])

  const handleConfirmMeetingActiveModalCancelClose = useCallback(() => {
    onClose && onClose(false)
  }, [onClose])

  return (
    <div className='modal fade' id='confirm_meeting_active' data-bs-backdrop='static'
      data-bs-keyboard='false' tabIndex={-1} aria-labelledby='confirmMeetingActiveModal'>
      <div className='modal-dialog'>
        <div className='modal-content' style={{ borderRadius: '1rem' }}>
          <div className='modal-header border-bottom-0'>
            <h5 className='modal-title' id='confirmMeetingActiveModal'>
              {t('会議終了予定時刻を過ぎています。')}<br />
              {t('会議を続けますか？')}
            </h5>
            <button type='button' className='btn-close' onClick={handleConfirmMeetingActiveModalCancelClose}></button>
          </div>
          <div className='modal-body'>
            <button type='button' className='btn btn-danger w-100 mb-3' onClick={handleConfirmMeetingActiveModalEndClose}>
              {t('全員ミーティングを終了する')}
            </button>
            <button type='button' className='btn btn-secondary w-100 mb-3' onClick={handleConfirmMeetingActiveModalCancelClose}>
              {t('会議を続ける')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
});
