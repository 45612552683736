import { useCallback, useEffect, useRef, useState } from 'react';
import { WaveFile } from 'wavefile';
import { LocalStorage } from '../store/LocalStorage';
import useQuery from './useQuery';
import { useCustomSpeech } from './useCustomSpeech';
import { useGoogleSpeech } from './useGoogleSpeech';
import { useAzureSpeech } from './useAzureSpeech';
import { UseSpeechArgs, UseSpeechHandles, useSttType, useWavRecordHandles, useWavRecordProps } from '../types/speech';

/**
 * マイクからの音声の録音と分析するカスタムReactフックを表現します。
 *
 * @param {Object} useWavRecordProps - フックの設定のためのプロパティ。
 * @param {string} useWavRecordProps.roomOwnerId - ルームオーナーのID。
 * @param {string} useWavRecordProps.meetingId - ミーティングのID。
 * @param {number} useWavRecordProps.clusterAmount - STTに送信する話者の数。
 * @param {function} useWavRecordProps.onSetDisabled - マイクボタンを無効化する関数。
 * @param {function} useWavRecordProps.onMessage - トランスクリプションメッセージを処理する関数。
 *
 * @returns {Object} useWavRecordHandles - 録音プロセスのためのコールバック関数と状態変数を含むオブジェクト。
 * @returns {boolean} useWavRecordHandles.isConnected - 状態が初期化され、接続が確立されていることを示すブール値。
 * @returns {boolean} useWavRecordHandles.isAnalyzed - マイクデバイスの情報が解析済みかどうかを示すブール値。
 * @returns {boolean} useWavRecordHandles.isStarted - 音声文字変換が開始されていることを示すブール値。
 * @returns {boolean} useWavRecordHandles.isMonitoring - 音声キャプチャが開始されていることを示すブール値。
 * @returns {boolean} useWavRecordHandles.isErrored - エラー発生を示すブール値。
 * @returns {function} useWavRecordHandles.build - 音声文字変換処理を開始するための関数。
 * @returns {function} useWavRecordHandles.start - 音声文字変換処理を再開するための関数。
 * @returns {function} useWavRecordHandles.stop - 音声文字変換処理を停止するための関数。
 * @returns {function} useWavRecordHandles.monitoringStart - 音声キャプチャを開始するための関数。
 * @returns {function} useWavRecordHandles.monitoringStop - 音声キャプチャを保存するための関数。
 * @returns {string} useWavRecordHandles.dataURL - 音声キャプチャの結果を格納するURLを含む文字列。
 * @returns {string} useWavRecordHandles.deviceId - マイクデバイスのIDを含む文字列。
 * @returns {ref} useWavRecordHandles.errorMessageRef - エラー発生時のメッセージを格納したRefオブジェクト。
 * @returns {ref} useWavRecordHandles.errorReportRef - エラー報告を格納したRefオブジェクト。
 */
export const useWavRecord = ({
  roomOwnerId,
  meetingId,
  clusterAmount,
  onSetDisabled,
  onMessage,
}: useWavRecordProps): useWavRecordHandles  => {
  // 録音機能で使用する音声データ
  const [chunks, setChunks] = useState<Blob[]>([]);
  // 録音機能で使用する音声データURL
  const [dataURL, setDataURL] = useState<string>('');

  // マイクデバイスID
  const [deviceId, setDeviceId] = useState<string>();

  // 音声デバイスと接続・解析したかのフラグ
  const [isDeviceAnalyzed, setIsDeviceAnalyzed] = useState<boolean>(false);
  // 文字起こしを開始したかのフラグ
  const [isStarted, setIsStarted] = useState<boolean>(false);
  // 音声をキャプチャ中かのフラグ
  const [isMonitoring, setIsMonitoring] = useState<boolean>(false);
  // エラーが発生したかのフラグ
  const [isErrored, setIsErrored] = useState<boolean>(false);

  // エラーメッセージ
  const errorMessageRef = useRef<string>('');
  // エラー詳細メッセージ
  const errorReportRef = useRef<string>('');
  // 録音機能で使用する音声情報
  const audioInfoRef = useRef<AudioInfo>();
  // 音声データストリーム
  const streamRef = useRef<MediaStream>();

  // 録音機能で使用する音声データ（Ref）
  const chunksRef = useRef<Blob[]>([]);
  // マイクデバイスID（Ref）
  const deviceIdRef = useRef<string>();
  // 文字起こしを開始したかのフラグ（Ref）
  const isStartedRef = useRef<boolean>(isStarted);

  // Refに伝播させる
  useEffect(() => {
   deviceIdRef.current = deviceId;
  }, [deviceId]);
  useEffect(() => {
    chunksRef.current = chunks;
  }, [chunks]);
  useEffect(() => {
    isStartedRef.current = isStarted;
  }, [isStarted]);

  // エラー発生時イベント
  let transcriptionStop: () => Promise<boolean> = useCallback(() => new Promise(resolve => resolve(true)), []);
  const onError = useCallback((message: string) => {
    if(isStartedRef.current){
      transcriptionStop();
    }
    errorMessageRef.current = '音声処理中にエラーが発生しました。このデバイスからの音声は非対応です。';
    errorReportRef.current = message;
    setIsErrored(true);

  }, [transcriptionStop]);

  // 音声キャプチャ用
  const onDataAvailable = useCallback(async (blob: Blob) => {
    const data = await blob.arrayBuffer();
    if (!audioInfoRef.current) {
      const wavHeader = data.slice(0, 44);
      const dataView = new DataView(wavHeader);
      const channel = dataView.getUint16(22, true);
      const bitDepth = dataView.getUint16(34, true);
      const encoding = `LINEAR${bitDepth}`;
      const sampleRate = dataView.getUint32(24, true);
      audioInfoRef.current = { channel, bitDepth, encoding, sampleRate };
    }
    const rawPCM = data.slice(44);
    setChunks(prevChunks => [...prevChunks, new Blob([rawPCM])]);
  }, []);

  // GETパラメータの取得
  const { getSttType } = useQuery();
  // STTの種類を取得
  const sttType = getSttType();

  // hookへ渡すパラメータ
  const speechArgs: UseSpeechArgs = {
    userId: meetingId + '_' + LocalStorage.uid,
    roomOwnerId: roomOwnerId,
    needInterim: true,
    clusterAmount: clusterAmount,
    onMessage: onMessage,
    onDataAvailable: onDataAvailable,
    onError: onError,
  };

  // 使用するSTTによって分岐
  let useSpeech: UseSpeechHandles;
  const customSpeech = useCustomSpeech(speechArgs); // カスタム STT
  const googleSpeech = useGoogleSpeech(speechArgs);// Google STT
  const azureSpeech = useAzureSpeech(speechArgs); // Azure STT
  switch (sttType){
    case useSttType.Custom:
      useSpeech = customSpeech; // カスタム STTを使用する
      break;
    case useSttType.Google:
      useSpeech = googleSpeech; // Google STTを使用する
      break;
    case useSttType.Azure:
      useSpeech = azureSpeech; // Azure STTを使用する
      break;
  }
  // 初期化フラグ、スタート・ストップ、使用開始関数を取得
  const { initialized, initializedRef, start: startSpeech, stop: stopSpeech, setUseStart } = useSpeech;
  // 使用開始をhookに伝える
  useEffect(() => {
    setUseStart(true);
  }, [setUseStart]);

  // マイクデバイスと接続
  const accessDevice = useCallback(async () => {
    try {
      const constraints: MediaStreamConstraints = {
        audio: {
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
          channelCount: 1,
        },
        video: false,
      };
      // デバイスIDが指定されている場合は設定
      if (deviceIdRef.current) {
        if (constraints.audio && typeof constraints.audio !== 'boolean') {
          constraints.audio.deviceId = deviceIdRef.current;
        }
      }
      streamRef.current = await navigator.mediaDevices.getUserMedia(constraints);
      return true;
    } catch (e: any) {
      errorMessageRef.current = 'マイクデバイスにアクセス中にエラーが発生しました。';
      errorReportRef.current = e.toString();
      console.error(e);
      setIsErrored(true);
    }
    return false;
  }, []);

  // マイクデバイスの情報を解析
  const analyzeDevice = useCallback(async () => {
    try {
      if (!streamRef.current) {
        errorMessageRef.current = 'マイクデバイスの情報取得中にエラーが発生しました。';
        errorReportRef.current = 'マイクアクセス処理が行われていません';
        setIsErrored(true);
      } else {
        let track = streamRef.current.getAudioTracks()[0];
        setDeviceId(track.getSettings().deviceId);
        setIsDeviceAnalyzed(true);
        return true;
      }
    } catch (e: any) {
      errorMessageRef.current = 'マイクデバイスの情報取得中にエラーが発生しました。';
      errorReportRef.current = e.toString();
      console.error(e);
      setIsErrored(true);
    }
    return false;
  }, []);

  // 文字起こし開始
  const transcriptionStart = useCallback(async () => {
    if (!streamRef.current) {
      errorMessageRef.current = '文字起こし開始中にエラーが発生しました。';
      errorReportRef.current = 'マイクデバイスの情報取得処理が行われていません';
      setIsErrored(true);
      return false;
    } else {
      try {
        await startSpeech(streamRef.current);
        setIsStarted(true);
        return true;
      } catch (e: any) {
        errorMessageRef.current = '文字起こし中にエラーが発生しました。';
        errorReportRef.current = e.toString();
        console.error(e);
        setIsErrored(true);
        return false;
      }
    }
  }, [startSpeech]);

  // 文字起こし停止
  transcriptionStop = useCallback(async () => {
    if (!streamRef.current) {
      errorMessageRef.current = '文字起こし開始中にエラーが発生しました。';
      errorReportRef.current = 'マイクデバイスの情報取得処理が行われていません';
      setIsErrored(true);
      return false;
    } else {
      try {
        stopSpeech();
        setIsStarted(false);
        return true;
      } catch (e: any) {
        errorMessageRef.current = '文字起こし中にエラーが発生しました。';
        errorReportRef.current = e.toString();
        console.error(e);
        setIsErrored(true);
        return false;
      }
    }
  }, [stopSpeech]);

  // 音声キャプチャ開始
  const monitoringStart = useCallback(() => {
    // chunksをクリアしてメモリを解放する
    setChunks([]);
    dataURL !== '' && URL.revokeObjectURL(dataURL);
    setDataURL('');
    setIsMonitoring(true);
  }, [dataURL]);

  // 音声キャプチャ終了（保存）
  const monitoringStop = useCallback(async () => {
    if (!audioInfoRef.current || chunksRef.current.length === 0) {
      errorMessageRef.current = '音声キャプチャ終了・保存中にエラーが発生しました。';
      errorReportRef.current = 'レコーダーが起動していません。';
      setIsErrored(true);
      return false;
    }

    // 各チャンクのArrayBufferを結合して、PCMデータを作成
    const audioDataArray = await Promise.all(chunksRef.current.map(async (chunk) => {
      return new Int16Array(await chunk.arrayBuffer());
    }));

    // 結合されたPCMデータ
    const totalLength = audioDataArray.reduce((acc, arr) => acc + arr.length, 0);
    const concatenatedData = new Int16Array(totalLength);
    let offset = 0;
    audioDataArray.forEach(data => {
      concatenatedData.set(data, offset);
      offset += data.length;
    });

    // ライブラリを使ってWAVファイルをエンコード
    const wav = new WaveFile();
    const bitDepthCode = `${audioInfoRef.current.bitDepth}`;
    wav.fromScratch(audioInfoRef.current.channel, audioInfoRef.current.sampleRate, bitDepthCode, concatenatedData);

    // ダウンロード処理
    const url = wav.toDataURI();
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'recording.wav';
    document.body.appendChild(a);
    a.click();
    setDataURL(url);

    // chunksをクリアしてメモリを解放する
    setChunks([]);
    setIsMonitoring(false);
    return true;
  }, []);

  // レコーダー機構構築
  const build = useCallback( async (start: boolean, disabled?: boolean | undefined, orderDeviceId?: string | undefined) => {
    onSetDisabled(true);
    if(orderDeviceId){
      deviceIdRef.current = orderDeviceId;
    }
    if(initializedRef.current){
      stopSpeech();
    }

    if (!await accessDevice()) return false;
    if (!await analyzeDevice()) return false;

    if(start){
      await transcriptionStart();
      onSetDisabled(false);
    }else{
      await transcriptionStop();
      !disabled && onSetDisabled(false);
    }
    return true;
  }, [accessDevice, analyzeDevice, initializedRef, onSetDisabled, stopSpeech, transcriptionStart, transcriptionStop]);

  // レコーダースタート
  const start = useCallback( async () => {
    onSetDisabled(true);
    if(!await transcriptionStart()) return false;
    onSetDisabled(false);
    return true;
  }, [onSetDisabled, transcriptionStart]);

  // レコーダーストップ
  const stop = useCallback( async (disabled?: boolean | undefined) => {
    onSetDisabled(true);
    if(!await transcriptionStop()) return false;
    !disabled && onSetDisabled(false);
    return true;
  }, [onSetDisabled, transcriptionStop]);

  useEffect(() => {
    // 設定が完了したら起動
    if(initialized){
      (async() => {
        await build(LocalStorage.autoStart, false);
      })();
    }
  }, [build, initialized]);

  return {
    isConnected: initialized,
    isAnalyzed: isDeviceAnalyzed,
    isStarted,
    isMonitoring,
    isErrored,
    build,
    start,
    stop,
    monitoringStart,
    monitoringStop,
    dataURL,
    deviceId,
    errorMessage: errorMessageRef,
    errorReport: errorReportRef,
  };
};
